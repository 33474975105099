import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Process = () => {
    return (
        <>
            {/* <!-- Process Area --> */}
            <div className="how-section pb-13 pt-lg-13 pb-lg-25 mb-23 mb-lg-29 bg-default-4" id="about">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl="6" lg="7" md="9">
                            <div className="section-title text-center mb-11 mb-lg-17">
                                <h2 className="title gr-text-3 mb-7 heading-color">ABOUT</h2>
                                <p className="px-lg-8 gr-text-8 text-color-opacity">
                                    A few words about GBA Company{" "}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <div className="gr-timeline-wrapper gr-flex-all-center">
                        <div
                            className="single-timeline-feature text-center px-6 px-md-9"
                            data-aos="zoom-in"
                            data-aos-duration="900"
                        >
                            <div className="count circle-lg bg-red gr-text-4 mx-auto mb-12 text-white">
                                <span>1</span>
                            </div>
                            <div className="content px-xl-7">
                                <h3 className="title gr-text-7 mb-6 heading-color">More than 30 years of experience</h3>
                                <p className="gr-text-9 text-color-opacity proc-p1" >
                                    It was in 1987 when we begun with a vision in our mind: create top-quality apparel for EU brands.{" "}
                                </p>
                            </div>
                        </div>
                        <div
                            className="single-timeline-feature text-center px-6 px-md-9"
                            data-aos="zoom-in"
                            data-aos-duration="900"
                            data-aos-delay="400"
                        >
                            <div className="count circle-lg bg-green gr-text-4 mx-auto mb-12 text-white">
                                <span>2</span>
                            </div>
                            <div className="content px-xl-7">
                                <h3 className="title gr-text-7 mb-6 heading-color">Establishing a brand</h3>
                                <p className="gr-text-9 text-color-opacity proc-p2" >
                                    In the years that followed, our passion for apparel and efficiency in manufacturing established our collaborations with bespoke brands and esteemed ourselves as the trustful and reliable partner, which clients and suppliers can rely on for adding value to their brands.{" "}
                                </p>
                            </div>
                        </div>
                        <div
                            className="single-timeline-feature text-center px-6 px-md-9"
                            data-aos="zoom-in"
                            data-aos-duration="900"
                            data-aos-delay="800"
                        >
                            <div className="count circle-lg bg-blue gr-text-4 mx-auto mb-12 text-white">
                                <span>3</span>
                            </div>
                            <div className="content px-xl-7">
                                <h3 className="title gr-text-7 mb-6 heading-color">Moving our base</h3>
                                <p className="gr-text-9 text-color-opacity proc-p3" >
                                    In 1999 we decided to move our factory to Bulgaria, in order to provide even more value to our European clientele.{" "}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="widget-text" style={{marginTop: "2rem"}} >
                        <h3 className="title heading-color gr-text-6 mb-7 logo-bef">Future steps</h3>
                        <p className="gr-text-9 text-color-opacity proc-last">
                            Using up to date technological equipment, having a highly trained staff and working with reliable and firmly established associates of our branch, we are able to manufacture a great variety of products, aiming to satisfy the full range of our client’s needs with our best quality promise and lead times.
                        </p>
                    </div>

                </Container>
            </div>
        </>
    );
};

export default Process;
