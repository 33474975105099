import React from "react";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import { Container, Row, Col } from "react-bootstrap";

const Fact = () => {
  return (
    <>
      {/* <!-- Fact Area --> */}
      <div className="fact-section pt-12 pb-6 pt-lg-19 pb-lg-17 border-bottom border-gray-3 bg-default-6 ">
        <Container>
          <Row className="justify-content-center">
            <Col lg="4" md="6" sm="6" className="mb-9 mb-lg-0">
              <div className="single-fact d-flex px-md-5 px-lg-2 px-xl-9">
                <h3 className="gr-text-3 me-9 heading-color">150+</h3>
                <p className="gr-text-9 mb-0 text-color-opacity">
                    Successful cooperations
                </p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" className="mb-9 mb-lg-0">
              <div className="single-fact d-flex px-md-5 px-lg-2 px-xl-9">
                <h3 className="gr-text-3 me-9 heading-color">
                  <LazyLoad>
                    <span className="counter">
                      <CountUp duration={3} end={100} />%
                    </span>
                  </LazyLoad>
                </h3>
                <p className="gr-text-9 mb-0 text-color-opacity">
                    Customer <div>Satisfaction</div>
                </p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" className="mb-9 mb-lg-0">
              <div className="single-fact d-flex px-md-5 px-lg-2 px-xl-9">
                <h3 className="gr-text-3 me-9 heading-color">
                  <LazyLoad>
                    <span className="counter">
                      <CountUp
                        duration={3}
                        end={30}
                      />
                    </span>
                  </LazyLoad>
                </h3>
                <p className="gr-text-9 mb-0 text-color-opacity">
                    Years <div>of experience!</div>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Fact;
