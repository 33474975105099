import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios"
import serialize from 'form-serialize';

import { Select } from "../../components/Core";
import { stubString } from "lodash";



const Cta = () => {
    const [emailResponse, setEmailResponse] = useState("")
    const [errorMssg, setErrorMssg] = useState("")

    function contactMessage(e) {
        e.preventDefault();

        let form = document.querySelector('#contactForm');
        let str = serialize(form, { hash: true });
        console.log(str)
        if(str.name && str.email && str.message && str.phone_number ){
            setErrorMssg("")
            axios.post(`https://admin.w3vitals.com/wp-json/myplugin/v1/gbaEmail`, str)
                .then(function (response) {
                    setEmailResponse(response.data)
                })
                .catch(function (error) {
                    console.log(error)
                })
            console.log("ok")
        }else {
            setErrorMssg("Please fill in all the required fields.")
        }

    }



    return (
        <>
            {/* <!-- CTA Area --> */}
            <div className="cta-section overflow-hidden py-13 py-lg-25 bg-mirage bg-pattern pattern-1 " id="quotation">
                <Container>
                    <Row className="align-items-center">
                        <Col xl="5" md="6" data-aos="fade-right" data-aos-duration="800">
                            <div className="section-title pe-lg-7 mb-0 dark-mode-texts">
                                <div className="chat-icon circle-xl gr-bg-white-opacity-1 text-green gr-text-4 mb-13">
                                    <i className="icon icon-chat-round-2 text-green"></i>
                                </div>
                                <h2 className="title gr-text-4 mb-6 text-white">
                                    Get in touch for a free quotation or to meet us!
                                </h2>
                                {/* <p className="gr-text-8 pe-lg-7 mb-0 text-color-opacity">
                    Get in touch for a free quotation or to meet us!
                </p> */}
                            </div>
                        </Col>
                        <Col
                            xl="4"
                            lg="5"
                            md="6"
                            className="offset-xl-3 offset-lg-1 mt-9 mt-md-0"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        >
                            {emailResponse === ""
                                ?
                                <form className="cta-form bg-white light-mode rounded-10 px-8 py-8" id="contactForm">
                                    <div className="form-group mb-6 gr-text-11 fw-bold text-blackish-blue">
                                        <label htmlFor="ctaName">Name *</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control gr-text-11 mt-3 bg-white"
                                            id="ctaName"
                                            placeholder="i.e. John Doe"
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-6 gr-text-11 fw-bold text-blackish-blue">
                                        <label htmlFor="ctaEmail">Email *</label>
                                        <input
                                            type="email"
                                            className="form-control gr-text-11 mt-3 bg-white"
                                            id="ctaEmail"
                                            name="email"
                                            placeholder="i.e. john@mail.com"
                                            required
                                        />
                                    </div>
                                    <div className="form-group mb-6 gr-text-11 fw-bold text-blackish-blue">
                                        <label htmlFor="ctaPhone">Phone *</label>
                                        <input
                                            type="text"
                                            name="phone_number"
                                            className="form-control gr-text-11 mt-3 bg-white"
                                            id="ctaPhone"
                                            placeholder="i.e. 123-456-7890"
                                        />
                                    </div>
                                    <div className="form-group mb-6 gr-text-11 fw-bold text-blackish-blue">
                                        <label htmlFor="agentCount" className="pr-lg-4">
                                            Message *{" "}
                                        </label>
                                        <textarea
                                            className="form-control gr-text-11 mt-3 bg-white"
                                            id="ctaMessage"
                                            name="message"
                                            placeholder="Your message"
                                            required
                                        />

                                    </div>
                                    {
                                        errorMssg && (
                                            <p style={{color: "red"}}>{errorMssg}</p>
                                        )
                                    }
                        
                                    <Button type="submit" className="gr-hover-y rounded-8 w-100" onClick={(e) => contactMessage(e)}>
                                        Send Message
                                    </Button>
                                </form>
                                :
                                <div className="email-response" style={{    backgroundColor: "white",padding: "2rem"}} dangerouslySetInnerHTML={{ __html: emailResponse }} />
                            }

                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Cta;
