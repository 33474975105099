import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

import imgC1 from "../../assets/image/gba/man/1-LEAF-BLACK-4-FRONT.jpg";
import imgC2 from "../../assets/image/gba/man/2-LEAF-BLACK-4-BACKS.jpg";
import imgC3 from "../../assets/image/gba/man/3-BLOCK-4-COLOUR.jpg";
import imgC4 from "../../assets/image/gba/man/4-LEAF-REAL-RED.jpg";
import imgC5 from "../../assets/image/gba/man/5-1920-Sorts.jpg";


import imgW1 from "../../assets/image/gba/women/1-GBA.jpg";
import imgW2 from "../../assets/image/gba/women/2-GBA.jpg";
import imgW3 from "../../assets/image/gba/women/3-GBA.jpg";
import imgW4 from "../../assets/image/gba/women/41-GBA.jpg";
import imgW5 from "../../assets/image/gba/women/5-GBA.jpg";
import imgW6 from "../../assets/image/gba/women/6-GBA.jpg";



import imgC6 from "../../assets/image/gba/youth/GBA4.jpg";

const images = [
    {
      original: imgC1,
      thumbnail: imgC1,
    },
    {
      original: imgC2,
      thumbnail: imgC2,
    },
    {
      original: imgC3,
      thumbnail: imgC3,
    },
    {
        original: imgC4,
        thumbnail: imgC4,
      },
      {
        original: imgC5,
        thumbnail: imgC5,
      },
  ];

  const imagesW = [
    {
      original: imgW1,
      thumbnail: imgW1,
    },
    {
      original: imgW2,
      thumbnail: imgW2,
    },
    {
      original: imgW3,
      thumbnail: imgW3,
    },
    {
        original: imgW4,
        thumbnail: imgW4,
    },
    {
        original: imgW5,
        thumbnail: imgW5,
    },
    {
        original: imgW6,
        thumbnail: imgW6,
    }
  ];

const CaseStudies = () => (
    <>
        {/* <!-- CaseStudies section --> */}
        <div className="case-section pt-15 pb-14 py-lg-25" id="portfolio">
            <Container>
                <Row className="justify-content-center">
                    <Col xl="6" lg="7" md="8">
                        <div className="section-title text-center mb-11 mb-lg-21">

                            <h2 className="title gr-text-4 mb-0 heading-color">
                                PORTFOLIO
                            </h2>
                            <p className="gr-text-9 mb-0 text-color-opacity">
                                Clothing Manufacturer, wholesale for Men’s, Women’s and Children’s clothing and garments.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs="12">
                        <div className="row mb-lg-9">
                            <div className="single-case d-inline-block px-md-6 mb-3 mb-lg-9 col-md-4">
                                <div className="case-img overflow-hidden">
                                    <ImageGallery 
                                        items={images}
                                        disableThumbnailScroll
                                        showPlayButton={false}
                                        showThumbnails={false}
                                        showNav={false}
                                        showFullscreenButton={false}
                                        autoPlay={true}
                                    />
                                    {/* <img src={imgC1} alt="" className="w-100 rounded-10" /> */}
                                </div>
                                <div className="case-content px-5 px-md-9 py-9" >
                                    <span className="case-category gr-text-11 mb-2 d-inline-block gr-text-color-opacity">
                                        Men
                                    </span>
                                    <h3 className="case-title heading-color gr-text-6 mb-0">
                                        Styles
                                    </h3>
                                </div>
                            </div>
                        

                            <div className="single-case d-inline-block px-md-6 mb-3 mb-lg-9 col-md-4">
                                <div className="case-img overflow-hidden">
                                    <ImageGallery 
                                        items={imagesW}
                                        disableThumbnailScroll
                                        showPlayButton={false}
                                        showThumbnails={false}
                                        showNav={false}
                                        showFullscreenButton={false}
                                        autoPlay={true}
                                    />
                                </div>
                                <div className="case-content px-5 px-md-9 py-9">
                                    <span className="case-category gr-text-11 mb-2 d-inline-block gr-text-color-opacity">
                                        Women
                                    </span>
                                    <h3 className="case-title heading-color gr-text-6 mb-0">
                                        Top
                                    </h3>
                                </div>
                            </div>
                            
                            <div className="single-case d-inline-block px-md-6 mb-3 mb-lg-9 col-md-4">
                                <div className="case-img overflow-hidden">
                                    <img src={imgC6} alt="" className="w-100 rounded-10" />
                                </div>
                                <div className="case-content px-5 px-md-9 py-9">
                                    <span className="case-category gr-text-11 mb-2 d-inline-block gr-text-color-opacity">
                                    Youth
                                    </span>
                                    <h3 className="case-title heading-color gr-text-6 mb-0">
                                    Sweatshirt
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
);

export default CaseStudies;
