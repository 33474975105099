import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing11/Hero";
import Fact from "../sections/landing11/Fact";
import Services from "../sections/landing11/Features";

import Cta from "../sections/landing11/Cta1";

import Newsletter from "../sections/landing11/Newsletter";
import CaseStudies from "../sections/landing11/CaseStudies";
import Process from "../sections/landing11/Process";

const Consultation = () => {
    return (
        <>
            <PageWrapper
                headerConfig={{
                    theme: "dark",
                    align: "left",
                    isFluid: true,
                    button: "cta", // cta, account, null
                    buttonText: "Free Quotation",
                }}
                footerConfig={{
                    theme: "light",
                }}
            >
                <Hero />
                <Fact />
                <Services />
                <CaseStudies />
                <Process />

                <div
                    className="google-map fluid-map-height position-relative overflow-hidden"
                    id="googleMap"
                    css={`
                            position: relative;
                            overflow: hidden;
                        `}
                >
                    <div
                        css={`
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            background-color: rgb(229, 227, 223);
                            `}
                    >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5963.971078809703!2d23.200161!3d41.634444!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5a624dd058fa378e!2sGBA%20Company!5e0!3m2!1sen!2sgr!4v1648110332256!5m2!1sen!2sgr" width="100%" height="650" style={{border:"0"}} allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
                <Cta />

                {/* <Newsletter /> */}
            </PageWrapper>
        </>
    );
};

export default Consultation;
